import classnames from 'classnames';
// import PropTypes from 'prop-types';
import React from 'react';
import styles from './BrandDiscounts.module.scss';
import { FormattedHTMLMessage } from 'react-intl';
import PageSectionHeader from '../PageSectionHeader/PageSectionHeader';
import BrandDiscountCard from '../BrandDiscountCard/BrandDiscountCard';
import Sliders from '../Sliders/Sliders';
import { getPagePath } from '../../utils/appRoutes';
import { ARD_SEARCH } from '../../utils/appRoutes.definitions';

const BrandDiscounts = ({ brandDiscounts = [], categoryPath, classList }) => {
  if (!brandDiscounts.length) return null;
  const brandsFilter = brandDiscounts.map((brand) => brand.name || brand[0]);
  return (
    <div className={classList?.root || styles.root}>
      <PageSectionHeader
        classList={{ root: classnames(styles.title) }}
        link={`${getPagePath(ARD_SEARCH, categoryPath)}?q=korting&brand=${brandsFilter
          .map((item) => encodeURIComponent(item))
          .join('|')}`}
      >
        <FormattedHTMLMessage
          // todo: migrate to latest react-intl
          tagName="div"
          defaultMessage="<span>Top</span> brand discounts"
          id="category.brandDiscounts.title"
        />
      </PageSectionHeader>
      <div className={classnames('container', styles.intro, 'col-12-xs')}>
        <FormattedHTMLMessage defaultMessage="Check top brand discounts:" id="category.brandDiscounts.text" />
      </div>
      <div className="container">
        <Sliders classList={{ root: styles.slidersRoot, slides: styles.slidesWrapper }} isRoundNavigation gap={16}>
          {brandDiscounts.map((brandItem, index) => {
            return (
              <BrandDiscountCard key={`brandDiscountLink${index}`} brandData={brandItem} categoryPath={categoryPath} />
            );
          })}
        </Sliders>
      </div>
    </div>
  );
};

// BrandDiscounts.propTypes = {

// };

export default BrandDiscounts;
